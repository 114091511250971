import React, { useEffect, useState } from "react";
import { DGDrawer } from "../../../components/DGDrawer";
import { RequestForm } from "../../productionInspection/product-inspection/UnderInspection/RequestForm";
import { UnderInspectionForm } from "../../productionInspection/product-inspection/UnderInspection/UnderInspectionForm";


export function GembaProductInspect({ setSelectedCard = () => { } }) {
  const [formOpen, setFormOpen] = useState(true);
  const [selectedRow_, setSelectedRow_] = useState(null);
  const [measureOpen, setMeasureOpen] = useState(false);
  const [subtitle, setSubtitle] = useState("");


  return (
    <>
      {" "}
      <DGDrawer
        Component={RequestForm}
        title="Production Inspection  -  Request"
        isOpen={formOpen}
        setOpen={(state, reqID) => {
          if (reqID) {
            setFormOpen(state);
            setSelectedRow_({ request_id: reqID });
            setMeasureOpen(true);
          } else {
            setFormOpen(state);
            setSelectedCard(-1);
          }
        }}
        subtitle={new Date().toLocaleDateString()}
        drawerWidth={1150}
        componentProps={1}
        needSave
      />
      <DGDrawer
        Component={UnderInspectionForm}
        title="Production Inspection  -  Measure"
        isOpen={measureOpen}
        setOpen={(state, res) => {
          setMeasureOpen(state);
          if (!state) {
            setSelectedCard(-1);
          }
        }}
        subtitle={subtitle}
        drawerWidth={1150}
        componentProps={selectedRow_}
        needSave
      />
    </>
  );
}
